import { useEffect, useState } from 'react'
import { IUserDataRow } from './HIDUsersTable'
import HIDUserAccountInfo from './HIDUserAccountInfo'
import FullScreenLoader from '../Shared/FullScreenLoader'
import HIDCreateAccount from './HIDCreateAccount'
import {
  IOrigoAccount,
  GET_ORIGO_ACCOUNT,
  IGetOrigoAccountData,
  IGetOrigoAccountVars,
} from '../../graphql/hid/getOrigoAccount'
import { useLazyQuery, ApolloError } from '@apollo/client'
import HIDCards from './HIDCards'
import Error from '../Shared/Error'
import HIDCardSettings from './HIDCardSettings'

interface IProps {
  userData: IUserDataRow
  listRefetch: () => void
}

const HIDAccount = ({ userData, listRefetch }: IProps) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [account, setAccount] = useState<IOrigoAccount>()
  const { login } = userData

  const [getMobileAccessAccount, { loading }] = useLazyQuery<
    IGetOrigoAccountData,
    IGetOrigoAccountVars
  >(GET_ORIGO_ACCOUNT, {
    variables: { login },
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => setErrorMessage(error.message),
    onCompleted: (data: IGetOrigoAccountData) => {
      setAccount(data?.getUser.origoAccounts[0])
    },
  })

  useEffect(() => {
    getMobileAccessAccount()
  }, [])

  if (loading) {
    return <FullScreenLoader />
  }

  const refetchData = async () => {
    await getMobileAccessAccount()
    await listRefetch()
  }

  return (
    <>
      <HIDUserAccountInfo account={account} />
      {!account && (
        <HIDCreateAccount userData={userData} refetch={refetchData} />
      )}
      {!!account && (
        <>
          <HIDCardSettings account={account} />
          <HIDCards userData={userData} />
        </>
      )}

      {errorMessage && <Error errorMessage={errorMessage} />}
    </>
  )
}

export default HIDAccount
