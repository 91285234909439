import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import FAIcon from '../Shared/FAIcon'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { navList } from '../../const/menuItems'
import { NavMenuItem } from '../../types/navigation'

const NavItem = ({
  item,
  isMenuOpen,
}: {
  item: NavMenuItem
  isMenuOpen?: boolean
}) => {
  const { label, icon, content } = item
  const { t } = useTranslation(['admin_menu'])
  const navigate = useNavigate()
  let { pathname } = useLocation()
  const [isMenuExpanded, setIsMenuExpanded] = useState(false)

  const routeTo = (route: string) => () => {
    navigate(route)
  }

  const toggleBool =
    (setBoolState: React.Dispatch<React.SetStateAction<boolean>>) => () =>
      setBoolState(prev => !prev)

  const isSelected = (route: string, isNestedListExpanded: boolean = true) =>
    !isMenuOpen || !isNestedListExpanded
      ? pathname.includes(route)
      : pathname.includes(route)

  return (
    <>
      <MainListItem onClick={toggleBool(setIsMenuExpanded)}>
        {icon && (
          <ListItemIcon>
            <FAIcon icon={icon} />
          </ListItemIcon>
        )}
        <ListItemText primary={t(label)} />
        {!!content && (isMenuExpanded ? <ExpandLess /> : <ExpandMore />)}
      </MainListItem>
      {!!content && (
        <Collapse in={isMenuOpen && isMenuExpanded} timeout="auto">
          <List component="div">
            {content.map(({ label, to, e2e }, i) => (
              <ListItem
                key={i}
                selected={isSelected(to, isMenuExpanded)}
                onClick={routeTo(to)}
                data-e2e={e2e}
              >
                <ListItemTextStyled primary={t(label)} />
              </ListItem>
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

const MainListItem = styled(ListItemButton)`
  min-height: 4rem;
`
const ListItemTextStyled = styled(ListItemText)`
  padding-left: 16px;
`
const ListItem = styled(ListItemButton)`
  padding: 8px 16px 8px 70px;
`

export default NavItem
