import { PropsWithChildren, useState } from 'react'
import styled from 'styled-components'
import {
  Button,
  ButtonBase,
  Divider,
  Drawer,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid,
} from '@mui/material'
import { ChevronLeft, ChevronRight, AccountCircle } from '@mui/icons-material'
import NavList from './NavList'
import { themeColors } from '../../const/colors'
import { useAppDispatch, useAppSelector } from '../../redux/store'
import { UserActionTypes } from '../../redux/user/types/UserActionTypes'
import { useTranslation } from 'react-i18next'
import { styled as styledMaterial } from '@mui/material/styles'
import { ToolbarPlaceholder } from '../Styles/ToolbarPlaceholder'
import PageContentContainer from '../PageContentContainer'

interface ICustomWindow extends Window {
  TAG?: string
}
declare var window: ICustomWindow

const AppBarStyled = styledMaterial(AppBar)({
  zIndex: 1500,
  backgroundColor: themeColors.white,
})

const Navigation = ({
  children,
}: PropsWithChildren<{}>) => {
  const { t } = useTranslation(['admin_menu'])
  const dispatch = useAppDispatch()
  const [isMenuOpen, setIsMenuOpen] = useState(true)

  const { fullName } = useAppSelector(state => state.user)

  const logout = () => dispatch({ type: UserActionTypes.LOGOUT })

  return (
    <NavRoot>
      <AppBarStyled position="fixed" enableColorOnDark color="default">
        <ToolbarStyled>
          <ButtonBase>
            <Logo>
              My <span>Olivia</span>
              <TextVersion>{window?.TAG || 'local'}</TextVersion>
            </Logo>
          </ButtonBase>
          <Button color="primary" onClick={logout} data-e2e="button-logout">
            WYLOGUJ
          </Button>
          <Grid>
            <Button
              color="primary"
              href="https://myolivia.blob.core.windows.net/myolivia/docs/faq/html/mycompany_faq_pl_v1.html"
              target="_blank"
            >
              FAQ
            </Button>
            <Button color="primary" startIcon={<AccountCircle />}>
              {fullName}
            </Button>
          </Grid>
        </ToolbarStyled>
      </AppBarStyled>
      <DrawerStyled variant="permanent" isOpen={isMenuOpen}>
        <ToolbarPlaceholder />
        <NavList
          isMenuOpen={isMenuOpen}
        />
        <Divider />
        <List disablePadding>
          <ListItem button onClick={() => setIsMenuOpen((prev) => !prev)}>
            <ListItemIcon>
              {isMenuOpen ? <ChevronLeft /> : <ChevronRight />}
            </ListItemIcon>
            <ListItemText primary={t('minimize_menu')} />
          </ListItem>
        </List>
      </DrawerStyled>
      <PageContent>{children}</PageContent>
    </NavRoot>
  )
}

const NavRoot = styled.div`
  display: flex;
`
const ToolbarStyled = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`
const Logo = styled.h1`
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  color: black;
  & > span {
    font-weight: 800;
    color: ${themeColors.primary};
  }
`
const TextVersion = styled.p`
  font-size: 14px;
  color: gray;
  margin: 0;
`
// warning fix "React does not recognize the "isOpen" prop on a DOM element."
const DrawerStyled = styled(({ isOpen, ...props }) => <Drawer {...props} />)<{
  isOpen?: boolean
}>`
  flex-shrink: 0;
  z-index: 1;
  &,
  & > .MuiDrawer-paper {
    width: ${({ isOpen }) => (isOpen ? '17rem' : '4.375rem')};
    overflow-x: ${({ isOpen }) => (isOpen ? 'initial' : 'hidden')};
  }
`
const PageContent = styled(PageContentContainer)`
  padding: 2.1875rem 1.5625rem;
`

export default Navigation
