import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useAppSelector } from '../../redux/store'
import {
  GET_TENANT,
  IGetTenantData,
  IGetTenantVars,
} from '../../graphql/tenant/getTenant'
import NoPlacesAssignedAlert from '../../components/Parking/NoPlacesAssignedAlert'

const ParkingPoolData = () => {
  const { t } = useTranslation(['parking_pool_data'])
  const { tenantName } = useAppSelector(state => state.user)

  const { data, loading } = useQuery<IGetTenantData, IGetTenantVars>(
    GET_TENANT,
    {
      fetchPolicy: 'cache-and-network',
      variables: { name: tenantName },
    },
  )

  if (loading || !data) {
    return <FullScreenLoader />
  }

  if (!data.getTenant?.parkingPool) {
    return <TypographyStyled variant="h6">{t('no_product')}</TypographyStyled>
  }

  return (
    <>
      <NoPlacesAssignedAlert />
      <TypographyStyled variant="h6">
        {t('admin_menu:parking_pool_data')}
      </TypographyStyled>
      <div>
        <ul>
          <li>
            {t('amano_product_id')}:{' '}
            {data?.getTenant.parkingPool?.parkingPass.productId}
          </li>
          <li>
            {t('places_amount')}:{' '}
            {data?.getTenant.parkingPool?.placesCountContract}
          </li>
          <li>
            {t('max_simultaneous_users')}:{' '}
            {data?.getTenant.parkingPool?.maxActiveUserCount}
          </li>
          <li>
            {t('max_continuous_days')}:{' '}
            {data?.getTenant.parkingPool?.maxDurationDays}
          </li>
          <li>
            {t('max_advanced_days')}:{' '}
            {data?.getTenant.parkingPool?.maxAdvanceDays}
          </li>
          <li>
            {t('max_simultaneous_reservations')}:{' '}
            {data?.getTenant.parkingPool?.maxReservationCountPerUser}
          </li>
        </ul>
      </div>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`

export default ParkingPoolData
