import styled from 'styled-components'
import { FormControl, MenuItem, InputLabel } from '@mui/material'
import Select from '@mui/material/Select'
import { useTranslation } from 'react-i18next'

const HIDAccountFilter = ({
  value,
  setValue,
}: {
  value: string
  setValue: (v: string) => void
}) => {
  const { t } = useTranslation(['hid'])
  const data = [
    {
      name: 'yes',
    },
    {
      name: 'no',
    },
  ]

  const handleChange = (event: any) => setValue(event.target.value)

  return (
    <FormControl>
      <InputLabel id="select-label">{t('mobile_access_card')}</InputLabel>
      <SelectStyled
        labelId="select-label"
        value={value}
        label="Mobilna karta dostępowa"
        onChange={handleChange}
      >
        {data.map(({ name }, i) => (
          <MenuItem key={i} value={name}>
            {t(`generic:${name}`)}
          </MenuItem>
        ))}
      </SelectStyled>
    </FormControl>
  )
}

export default HIDAccountFilter

const SelectStyled = styled(Select)`
  width: 250px;
`
