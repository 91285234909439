import { Grid } from '@mui/material'
import styled from 'styled-components'
import { TextBody1Bold, TextBody1Medium, Title } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { IOrigoAccount } from '../../graphql/hid/getOrigoAccount'
import { themeColors } from '../../const/colors'
import { OrigoEventName } from '../../graphql/hid/getOrigoAccount'

export const accountUnderCreation = [
  OrigoEventName.NEW,
  OrigoEventName.USER_CREATED,
]

const HIDUserAccountInfo = ({ account }: { account?: IOrigoAccount }) => {
  const { t } = useTranslation(['hid'])

  if (!account) {
    return null
  }
  const { status, userId, withParkingAccess } = account

  const data = [
    { label: 'Status', value: status },
    { label: 'User ID', value: userId },
  ]

  return (
    <PaperSection>
      <TitleStyled>{`${t('hid_account')}:`}</TitleStyled>

      {data.map(item => (
        <Row key={item.label}>
          <Text>{`${item.label}: `}</Text>
          <TextBold>{item.value ? item.value : 'Brak'}</TextBold>
        </Row>
      ))}
    </PaperSection>
  )
}

export default HIDUserAccountInfo
const Text = styled(TextBody1Medium)`
  display: inline;
`
const TextBold = styled(TextBody1Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px 0;
`
const TitleStyled = styled(Title)`
  display: block;
  margin: 0 0 16px 0;
`
const PaperSection = styled.div`
  background-color: ${themeColors.white};
  border-radius: 16px;
  padding: 20px 16px;
  margin: 16px 0;
`
