import { useMemo, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import RTTable from '../RTTable'
import { getBoolColor } from '../../utils/getColor'
import { IHIDUser } from '../../graphql/hid/queryUser'
import { OrigoEventName } from '../../graphql/hid/getOrigoAccount'
import { deletedStatuses } from '../../pages/HID/HIDUserList'

interface ITableColumns {
  Header: string
  accessor: 'fullName' | 'login' | 'workEmail' | 'tenantName' | 'hidAccount'
}

export interface IUserDataRow {
  fullName: string
  firstName: string
  lastName: string
  login: string
  workEmail: string
  tenantName: string
  tenantId: string
  tenantSlug: string
  hidAccount: boolean
  userId: string
  accountStatus?: OrigoEventName
}

const HIDUsersTable = ({
  children,
  users,
  setOpenUserDetails,
}: {
  children: ReactNode
  users: IHIDUser[]
  setOpenUserDetails: (v: IUserDataRow | undefined) => void
}) => {
  const { t } = useTranslation(['hid'])

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('columns:full_name'),
        accessor: 'fullName',
      },
      {
        Header: t('columns:login'),
        accessor: 'login',
      },
      {
        Header: t('columns:work_email'),
        accessor: 'workEmail',
      },
      {
        Header: t('columns:company'),
        accessor: 'tenantName',
      },
      {
        Header: t('mobile_access_card'),
        accessor: 'hidAccount',
        Cell: (props: any) => (!!props.value ? t('bool:yes') : t('bool:no')),
      },
    ],
    [t],
  )

  const tableData: IUserDataRow[] = useMemo(
    () =>
      users.map(
        ({
          login,
          firstName,
          lastName,
          email,
          workEmail,
          tenant,
          origoAccounts,
        }) => ({
          login,
          firstName,
          lastName,
          fullName: `${firstName} ${lastName}`,
          workEmail: workEmail?.email ? workEmail.email : email.email,
          tenantName: tenant.name || '',
          tenantId: tenant.id || '',
          tenantSlug: tenant.slug,
          hidAccount: !!origoAccounts.length,
          userId: !!origoAccounts.length ? origoAccounts[0].userId : '',
          accountStatus: origoAccounts[0]?.status,
        }),
      ) || [],
    [users],
  )

  return (
    <RTTable
      columns={tableColumns}
      data={tableData}
      onRowClick={rowData => setOpenUserDetails(rowData)}
      pagination
      orderByProps="fullName"
      sortDesc={false}
      rowSelect
      csvExport
      getCellProps={({ column, row }) =>
        column.id === 'hidAccount'
          ? {
              style: {
                color: getBoolColor(row.original.hidAccount),
                fontWeight: 600,
              },
            }
          : {}
      }
    >
      {children}
    </RTTable>
  )
}

export default HIDUsersTable
