import { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import {
  IParkingPoolUser,
  IQueryUsersData,
  IQueryUsersVars,
  QUERY_USERS_LIST,
} from '../../graphql/users/queryUsersList'
import {
  IGetTenantParkingPoolUsersVars,
  IGetTenantParkingPoolUsersData,
  GET_TENANT_PARKING_POOL_USERS,
  IParkingPool,
} from '../../graphql/parkingPool/getTenantParkingPoolUsers'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useAppSelector } from '../../redux/store'
import BaseDrawer from '../../components/Shared/BaseDrawer'
import UserDetails from '../../components/ParkingUserDetails/UserDetails'
import { Grid } from '@mui/material'
import NoPlacesAssignedAlert from '../../components/Parking/NoPlacesAssignedAlert'
import AssignedUsersFilter from '../../components/Parking/AssignedUsersFilter'
import ParkingPoolUsersTable, {
  IUserDataRow,
} from '../../components/Parking/ParkingPoolUsersTable'
import ParkingPoolProfileFilter from '../../components/Parking/ParkingPoolProfileFilter'

const UsersList = () => {
  const { t } = useTranslation(['admin_menu'])
  const { tenantName } = useAppSelector(state => state.user)
  const [openedUser, setOpenedUser] = useState<IUserDataRow>()
  const [assignedFilter, setAssignedFilter] = useState('')
  const [profileFilter, setProfileFilter] = useState('')
  const [filteredUsers, setFilteredUsers] = useState<IParkingPoolUser[]>([])
  const [parkingPool, setParkingPool] = useState<IParkingPool | undefined>()

  const { data, loading, refetch } = useQuery<IQueryUsersData, IQueryUsersVars>(
    QUERY_USERS_LIST,
    {
      fetchPolicy: 'no-cache',
      variables: {
        tenant: tenantName,
      },
    },
  )

  const {
    data: users,
    refetch: refetchUsers,
    loading: tenantLoading,
  } = useQuery<IGetTenantParkingPoolUsersData, IGetTenantParkingPoolUsersVars>(
    GET_TENANT_PARKING_POOL_USERS,
    {
      fetchPolicy: 'no-cache',
      variables: {
        tenant: tenantName,
      },
      onCompleted: (data: IGetTenantParkingPoolUsersData) => {
        setParkingPool(data?.getTenant.parkingPool)
      },
    },
  )

  const filterUsers = (login: string) =>
    users?.getTenant.parkingPool.users?.find(item => item.login === login)

  useEffect(() => {
    if (data) {
      let items = data.queryUser
      if (!!assignedFilter) {
        assignedFilter === 'yes'
          ? (items = items.filter(({ login }) => !!filterUsers(login)))
          : (items = items.filter(({ login }) => !filterUsers(login)))
      }
      if (profileFilter) {
        items = items.filter(
          ({ parkingPoolProfile }) =>
            parkingPoolProfile?.name === profileFilter,
        )
      }
      setFilteredUsers(items)
    }
  }, [data, assignedFilter, profileFilter])

  const refetchData = () => {
    refetch()
    refetchUsers()
  }

  const parkingPoolUsers = users?.getTenant.parkingPool?.users || []

  if (loading || tenantLoading) {
    return <FullScreenLoader />
  }

  if ((!!data && !users?.getTenant.parkingPool?.users) || !parkingPool) {
    return (
      <TypographyStyled variant="h6">
        {t('parking_pool:no_users')}
      </TypographyStyled>
    )
  }

  return (
    <>
      <NoPlacesAssignedAlert />
      <TypographyStyled variant="h6">{t('users_list')}</TypographyStyled>
      <ParkingPoolUsersTable
        users={filteredUsers}
        assigned={parkingPoolUsers}
        setOpenUserDetails={setOpenedUser}
        parkingPool={parkingPool}
      >
        <FiltersWrapper>
          <AssignedUsersFilter
            value={assignedFilter}
            setValue={setAssignedFilter}
          />
          {!!parkingPool?.id && (
            <ParkingPoolProfileFilter
              value={profileFilter}
              setValue={setProfileFilter}
              id={parkingPool.id}
            />
          )}
        </FiltersWrapper>
      </ParkingPoolUsersTable>
      <BaseDrawer open={!!openedUser}>
        {!!openedUser && !!parkingPool?.id && (
          <UserDetails
            userData={openedUser}
            closeDrawer={() => setOpenedUser(undefined)}
            listRefetch={refetchData}
            tenantName={tenantName}
            parkingPoolId={parkingPool.id}
          />
        )}
      </BaseDrawer>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`
const FiltersWrapper = styled(Grid)`
  padding: 16px 0;
`

export default UsersList
