import { Grid } from '@mui/material'
import styled from 'styled-components'
import { TextBody1Bold, TextBody1Medium, Title } from '../Styles/TextCustom'
import { useTranslation } from 'react-i18next'
import { IOrigoCard } from '../../graphql/hid/getOrigoAccount'
import { themeColors } from '../../const/colors'
import BaseLink from '../Shared/BaseLink'
import { getEnv } from '../../utils/env'

const MobileCard = ({ card }: { card: IOrigoCard }) => {
  const { t } = useTranslation(['hid'])
  const {
    status,
    current,
    cardNumber,
    privilegeRequested,
    privilegeGranted,
    accessGranted,
    invitationCode,
    deviceId,
    origoEndpoint,
    mysolvyTicketId,
    mysolvyTicketNr,
    mysolvyTicketStatus,
  } = card

  return (
    <>
      <Row>
        <TitleStyled>{`${t('card_number')}: `}</TitleStyled>
        <TextBold>{cardNumber}</TextBold>
        {!current && (
          <TitleStyled color={themeColors.error}>{` ${t(
            'inactive',
          )} `}</TitleStyled>
        )}
      </Row>
      <Row>
        <Text>{'Status'}: </Text>
        <TextBold>{status}</TextBold>
      </Row>
      <Row>
        <Text>{t('active')}: </Text>
        <TextBold>{String(current)}</TextBold>
      </Row>
      <Row>
        <Text>{t('privileges_requested')}: </Text>
        <TextBold>{String(privilegeRequested)}</TextBold>
      </Row>
      <Row>
        <Text>{t('privileges_granted')}: </Text>
        <TextBold>{String(privilegeGranted)}</TextBold>
      </Row>
      <Row>
        <Text>{t('access_granted')}: </Text>
        <TextBold>{String(accessGranted)}</TextBold>
      </Row>
      <Row>
        <Text>{'Invitation Code'}: </Text>
        <TextBold>{invitationCode ? invitationCode : 'Brak'}</TextBold>
      </Row>
      <Row>
        <Text>{'Device ID'}: </Text>
        <TextBold>{deviceId ? deviceId : '-'}</TextBold>
      </Row>
      <Row>
        <Text>{t('device')}: </Text>
        <TextBold>
          {origoEndpoint
            ? `${origoEndpoint?.model}, os: ${origoEndpoint?.os}`
            : '-'}
        </TextBold>
      </Row>
      <Row>
        <Text>{'ID zgłoszenia MySolvy'}: </Text>
        <TextBold>{String(mysolvyTicketId)}</TextBold>
      </Row>
      <Row>
        <Text>{'Numer zgłoszenia MySolvy'}: </Text>
        <TextBold>{String(mysolvyTicketNr)}</TextBold>
      </Row>
      <Row>
        <Text>{'Status zgłoszenia'}: </Text>
        <TextBold>{String(mysolvyTicketStatus)}</TextBold>
      </Row>
      {mysolvyTicketId && (
        <Row>
          <ButtonWrapper>
            <BaseLink
              path={`${getEnv(
                'MYSOLVY_URL',
              )}/ticket_details/${mysolvyTicketId}/`}
              text={t('mysolvy_task_link')}
            />
          </ButtonWrapper>
        </Row>
      )}
    </>
  )
}

export default MobileCard
const Text = styled(TextBody1Medium)`
  display: inline;
`
const TextBold = styled(TextBody1Bold)`
  display: inline;
`
const Row = styled(Grid)`
  padding: 2px 0;
`
const TitleStyled = styled(Title)`
  margin-bottom: 16px;
`
const ButtonWrapper = styled.div`
  padding: 16px 0;
`
