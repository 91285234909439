import { ReactNode, useMemo } from 'react'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import RTTable from '../../components/RTTable'
import { IParkingPoolSubscription } from '../../graphql/tenant/getTenant'
import {
  dateIso2localeString,
  dateIso2ExcelFormat,
} from '../../utils/formatDate'
import {
  ParkingSubscriptionStatus,
  ReservationStatus,
} from '../../graphql/tenant/getTenant'
import {
  getReservationStatusColor,
  getSubscriptionStatusColor,
} from '../../utils/getColor'

interface ITableColumns {
  Header: string
  accessor:
    | 'place'
    | 'parking'
    | 'fullName'
    | 'email'
    | 'cardNumber'
    | 'cardNumberCSV'
    | 'validFrom'
    | 'validFromCSV'
    | 'validTo'
    | 'validToCSV'
    | 'reservationStatus'
    | 'reservationStatusCSV'
    | 'subscriptionStatus'
    | 'subscriptionStatusCSV'
}

export interface ITableRow {
  place?: string
  parking: string
  fullName: string
  login: string
  email: string
  cardNumber: string
  cardNumberCSV: string
  validFrom: string
  validFromCSV: string
  validTo: string
  validToCSV: string
  reservationStatus: ReservationStatus
  reservationStatusCSV: string
  subscriptionStatus: ParkingSubscriptionStatus
  subscriptionStatusCSV: string
}

const ReservationTable = ({
  children,
  items,
  showPlaces,
}: {
  children: ReactNode
  items: IParkingPoolSubscription[]
  showPlaces: boolean
}) => {
  const { t, i18n } = useTranslation(['columns', 'user_details', 'admin_menu'])
  const lang = i18n.language

  const trimToFormat = (value: string) => {
    let string = value
    if (string.includes(',')) {
      string = string.replace(',', '.')
    } else if (string.includes(':')) {
      string = string.replace(':', '.')
    }
    const pattern = /(\d+)\.(\d{5})/
    const match = string.match(pattern)

    if (match && match[1].length > 3) {
      let digitsBeforeDot = match[1]
      digitsBeforeDot = digitsBeforeDot.slice(-3)

      return `${digitsBeforeDot}.${match[2]}`
    } else if (match && match[1].length < 3) {
      let digitsBeforeDot = match[1]
      digitsBeforeDot = `0${digitsBeforeDot}`

      return `${digitsBeforeDot}.${match[2]}`
    } else {
      console.log(value, string)
      return string
    }
  }

  const csvHeaders = [
    { label: 'Parking', key: 'parking' },
    { label: t('full_name'), key: 'fullName' },
    { label: t('work_email'), key: 'email' },
    { label: t('card_number'), key: 'cardNumberCSV' },
    { label: t('valid_from'), key: 'validFromCSV' },
    { label: t('valid_to'), key: 'validToCSV' },
    { label: t('reservation_status'), key: 'reservationStatusCSV' },
    { label: t('subscription_status'), key: 'subscriptionStatusCSV' },
  ]

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('parking'),
        accessor: 'parking',
      },
      {
        Header: t('full_name'),
        accessor: 'fullName',
      },
      { Header: t('work_email'), accessor: 'email' },
      {
        Header: t('card_number'),
        accessor: 'cardNumber',
        Cell: (props: { value: string }) => trimToFormat(props.value),
      },
      {
        Header: t('card_number'),
        accessor: 'cardNumberCSV',
        isVisible: false,
      },
      {
        Header: t('valid_from'),
        accessor: 'validFrom',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('valid_from'),
        accessor: 'validFromCSV',
        isVisible: false,
      },
      {
        Header: t('valid_to'),
        accessor: 'validTo',
        Cell: (props: { value: string }) =>
          dateIso2localeString(props.value, lang),
      },
      {
        Header: t('valid_to'),
        accessor: 'validToCSV',
        isVisible: false,
      },
      {
        Header: t('reservation_status'),
        accessor: 'reservationStatus',
        Cell: (props: { value: string }) =>
          t(`reservation_status:${props.value}`),
      },
      {
        Header: t('reservation_status'),
        accessor: 'reservationStatusCSV',
        isVisible: false,
      },
      {
        Header: t('subscription_status'),
        accessor: 'subscriptionStatus',
        Cell: (props: { value: string }) =>
          t(`parking_subscription_status:${props.value}`),
      },
      {
        Header: t('subscription_status'),
        accessor: 'subscriptionStatusCSV',
        isVisible: false,
      },
      {
        Header: t('place'),
        accessor: 'place',
        isVisible: showPlaces,
      },
    ],
    [t, lang, showPlaces],
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      items.map(
        ({
          reservations,
          validFrom,
          validTo,
          status,
          user: { login, firstName, lastName, email, workEmail, accessCard },
        }) => ({
          login: login,
          email: workEmail?.email ? workEmail?.email : email?.email,
          cardNumber: accessCard?.number || '',
          cardNumberCSV: trimToFormat(accessCard?.number) || '',
          fullName: `${firstName} ${lastName}`,
          validFrom,
          validFromCSV: dateIso2ExcelFormat(validFrom),
          validTo,
          validToCSV: dateIso2ExcelFormat(validTo),
          place: !!reservations[0]?.place?.shared
            ? reservations[0]?.place?.name
            : '-',
          subscriptionStatus: status,
          subscriptionStatusCSV: t(`parking_subscription_status:${status}`),
          reservationStatus: reservations[0].status,
          reservationStatusCSV: t(
            `reservation_status:${reservations[0].status}`,
          ),
          parking: reservations[0].place.parking.name,
        }),
      ) || []
    )
  }, [items, t])

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:reservations')}
      </TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        pagination
        orderByProps="validFrom"
        sortDesc={false}
        rowSelect
        csvExport
        csvHeaders={csvHeaders}
        getCellProps={({ column, row }) =>
          column.id === 'reservationStatus'
            ? {
                style: {
                  color: getReservationStatusColor(
                    row.original.reservationStatus,
                  ),
                  fontWeight: 600,
                },
              }
            : column.id === 'subscriptionStatus'
            ? {
                style: {
                  color: getSubscriptionStatusColor(
                    row.original.subscriptionStatus,
                  ),
                  fontWeight: 600,
                },
              }
            : {}
        }
      >
        {children}
      </RTTable>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`

export default ReservationTable
