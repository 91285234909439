import { gql } from '@apollo/client'

export interface IHIDConfig {
  enabled: boolean
  userCanBuy: boolean
  maxNumberOfMobileCards: number
}

export interface IGetHIDConfigVars {
  name: string
}

export interface IGetHIDConfigData {
  getTenant: {
    hidConfig?: IHIDConfig
    employees: {
      accounts: {
        current: boolean
      }[]
    }[]
  }
}

export const GET_HID_CONFIG = gql`
  query getTenant($name: String) {
    getTenant(name: $name) {
      hidConfig: hid_config {
        enabled
        userCanBuy: user_can_buy
        maxNumberOfMobileCards: max_number_of_mobile_cards
      }
      employees @cascade(fields: "origo_accounts") {
        accounts: origo_accounts(filter: { current: true }) {
          current
        }
      }
    }
  }
`
