import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ApolloError, useMutation } from '@apollo/client'
import {
  DELETE_MOBILE_USER_ACCOUNT,
  IDeleteMobileUserAccountVars,
  IDeleteMobileUserAccountData,
} from '../../graphql/hid/deleteMobileUserAccount'
import { themeColors } from '../../const/colors'
import { toast } from 'react-toastify'
import BaseModal from '../Shared/BaseModal'
import { ModalContent } from '../Styles/CustomElementsStyled'
import { BaseTextButton } from '../Shared/BaseButton'
import styled from 'styled-components'
import Error from '../Shared/Error'
import { IOrigoCard } from '../../graphql/hid/getOrigoAccount'
import { TextBody1Medium } from '../Styles/TextCustom'
import { useForm } from 'react-hook-form'
import Input from '../Shared/Input'

type FormData = {
  comment: string
}

const DeleteHIDCard = ({
  card,
  refetch,
  setDeletionProcess,
}: {
  card: IOrigoCard
  refetch: () => void
  setDeletionProcess: (v: boolean) => void
}) => {
  const { t } = useTranslation(['hid'])
  const [openModal, setOpenModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const successNotify = (message: string) => toast.dark(message)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>()

  const { user, deviceId, cardNumber } = card

  const onCompleted = async (data: IDeleteMobileUserAccountData) => {
    const { status, error } = data.DeleteMobileUserAccount
    if (status) {
      await refetch()
      await successNotify(t('card_deleted'))
      await setDeletionProcess(true)
    } else error && setErrorMessage(`${error}`)
  }

  const [deleteHIDCard] = useMutation<
    IDeleteMobileUserAccountData,
    IDeleteMobileUserAccountVars
  >(DELETE_MOBILE_USER_ACCOUNT, {
    onCompleted,
    onError: (error: ApolloError) => setErrorMessage(error.message),
  })

  const deleteCard = async (data: FormData) => {
    const { comment } = data
    await setDeletionProcess(true)
    await deleteHIDCard({ variables: { login: user.login, deviceId, comment } })
    setOpenModal(false)
  }

  if (!deviceId) {
    return (
      <TextBody1Medium color={themeColors.error}>
        {t('no_device_warning')}
      </TextBody1Medium>
    )
  }

  return (
    <>
      <Input
        label={t('deletion_comment')}
        name={'comment'}
        control={control}
        errors={errors}
        multiline
        rows={2}
      />
      <ButtonStyled
        onClick={() => setOpenModal(true)}
        customColor={themeColors.negative}
      >
        {t('delete_HID_card')}
      </ButtonStyled>
      {errorMessage && <Error errorMessage={errorMessage} />}
      <BaseModal
        confirmText={'btn:confirm'}
        open={openModal}
        handleClose={() => setOpenModal(false)}
        handleAccept={handleSubmit(deleteCard)}
      >
        <ModalContent>{`${t(
          'delete_card_confirmation',
        )}: ${cardNumber}?`}</ModalContent>
      </BaseModal>
    </>
  )
}

export default DeleteHIDCard

const ButtonStyled = styled(BaseTextButton)`
  margin-top: 36px;
`
