import { useQuery } from '@apollo/client'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { useAppSelector } from '../../redux/store'
import {
  GET_HID_CONFIG,
  IGetHIDConfigData,
  IGetHIDConfigVars,
} from '../../graphql/hid/getHIDConfig'

const HIDServiceData = () => {
  const { t } = useTranslation(['hid'])
  const { tenantName } = useAppSelector(state => state.user)

  const { data, loading } = useQuery<IGetHIDConfigData, IGetHIDConfigVars>(
    GET_HID_CONFIG,
    {
      fetchPolicy: 'no-cache',
      variables: {
        name: tenantName,
      },
    },
  )

  if (loading) {
    return <FullScreenLoader />
  }

  if (!data?.getTenant?.hidConfig) {
    return (
      <TypographyStyled variant="h6">
        {t('no_hid_account_assigned')}
      </TypographyStyled>
    )
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:hid_data')}
      </TypographyStyled>
      <div>
        <ul>
          <li>
            {t('max_number_of_mobile_cards')}:{' '}
            {data?.getTenant.hidConfig.maxNumberOfMobileCards}
          </li>
          <li>
            {t('used_cards')}: {data?.getTenant.employees.length}
          </li>
        </ul>
      </div>
    </>
  )
}

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
`

export default HIDServiceData
