import { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  dateIso2localeString,
  date2isoStringWithTimezone,
} from '../../utils/formatDate'
import RTTable from '../../components/RTTable'
import { Typography } from '@mui/material'
import styled from 'styled-components'
import { ApolloError, useLazyQuery, useQuery } from '@apollo/client'
import FullScreenLoader from '../../components/Shared/FullScreenLoader'
import { TextError } from '../../components/Styles/TextCustom'
import DateRangeFilter from '../../components/Parking/DateRangeFilter'
import {
  STAT_PARKING_POOL_DAILY_USAGE,
  IStatParkingPoolDailyUsageData,
  IStatParkingPoolDailyUsageVars,
} from '../../graphql/parkingPool/statParkingPoolDailyUsage'
import {
  GET_TENANT,
  IGetTenantData,
  IGetTenantVars,
} from '../../graphql/tenant/getTenant'
import { useAppSelector } from '../../redux/store'
import Error from '../../components/Shared/Error'
interface ITableColumns {
  Header: string
  accessor:
    | 'date'
    | 'parking'
    | 'capacity'
    | 'sold'
    | 'used'
    | 'maxUserCount'
    | 'placesCount'
    | 'maxPlacesCount'
}

export interface ITableRow {
  date: string
  parking: string
  capacity: number
  sold: number
  used: number
  maxUserCount?: number
  placesCount?: number
  maxPlacesCount?: number
}

const now = new Date(new Date().setHours(0, 0, 0, 0))
const start = new Date(now.setDate(now.getDate() - 30))
const end = new Date()

const ParkingOccupancyReport = () => {
  const { t, i18n } = useTranslation(['parking_pool_occupancy_report'])
  const lang = i18n.language
  const [rangeFrom, setRangeFrom] = useState<Date | null>(start)
  const [rangeTo, setRangeTo] = useState<Date | null>(end)
  const [error, setError] = useState('')
  const { tenantName } = useAppSelector(state => state.user)

  const { data: tenant, loading: tenantLoading } = useQuery<
    IGetTenantData,
    IGetTenantVars
  >(GET_TENANT, {
    fetchPolicy: 'cache-and-network',
    variables: { name: tenantName },
  })

  const [parkingPoolDailyUsage, { data, loading }] = useLazyQuery<
    IStatParkingPoolDailyUsageData,
    IStatParkingPoolDailyUsageVars
  >(STAT_PARKING_POOL_DAILY_USAGE, {
    fetchPolicy: 'no-cache',
    onError: (error: ApolloError) => {
      setError(error.message)
    },
  })

  const tableColumns: ITableColumns[] = useMemo(
    () => [
      {
        Header: t('date'),
        accessor: 'date',
        Cell: (props: { value: string }) =>
          !!props.value &&
          dateIso2localeString(props.value, lang, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }),
      },
      {
        Header: 'Parking',
        accessor: 'parking',
      },
      {
        Header: t('sold'),
        accessor: 'sold',
      },
      {
        Header: t('places_count'),
        accessor: 'placesCount',
      },
      {
        Header: t('max_places_count'),
        accessor: 'maxPlacesCount',
      },
      {
        Header: t('capacity'),
        accessor: 'capacity',
      },
      {
        Header: t('max_user_count'),
        accessor: 'maxUserCount',
      },
    ],
    [t, lang],
  )

  const tableData: ITableRow[] = useMemo(() => {
    return (
      data?.statParkingPoolDailyUsage?.stats?.map(
        ({ date, parkingName, capacity, sold, used }) => ({
          date,
          parking: parkingName,
          capacity,
          sold,
          used,
          maxUserCount: tenant?.getTenant.parkingPool?.maxActiveUserCount,
          placesCount: tenant?.getTenant.parkingPool?.placesAggregate.count,
          maxPlacesCount: tenant?.getTenant.parkingPool?.placesCountContract,
        }),
      ) || []
    )
  }, [data, tenant])

  useEffect(() => {
    if (rangeFrom && rangeTo && !!tenant?.getTenant.parkingPool)
      parkingPoolDailyUsage({
        variables: {
          tenant: tenantName,
          parkingName: tenant.getTenant.parkingPool.parkingPass.parking.name,
          from: date2isoStringWithTimezone(rangeFrom),
          to: date2isoStringWithTimezone(rangeTo),
        },
      })
  }, [rangeFrom, rangeTo, tenant])

  if (loading || tenantLoading) {
    return <FullScreenLoader />
  }

  return (
    <>
      <TypographyStyled variant="h6">
        {t('admin_menu:occupancy_report')}
      </TypographyStyled>
      <RTTable
        columns={tableColumns}
        data={tableData}
        rowSelect={false}
        pagination
        orderByProps="date"
        sortDesc={false}
        gFilter={false}
      >
        <DateRangeFilter
          rangeFrom={rangeFrom}
          rangeTo={rangeTo}
          setRangeFrom={setRangeFrom}
          setRangeTo={setRangeTo}
        />
      </RTTable>
      {!!error && <Error errorMessage={error} />}
    </>
  )
}

export default ParkingOccupancyReport

const TypographyStyled = styled(Typography)`
  font-weight: 600;
  padding-bottom: 1rem;
  padding-right: 10px;
`
