import { Route, Routes } from 'react-router'
import { ApolloError, useQuery } from '@apollo/client'
import { useAppDispatch } from '../redux/store'
import { UserActionTypes } from '../redux/user/types/UserActionTypes'
import {
  GET_MY_PROFILE_QUERY,
  IGetMyProfileData,
  IMyProfile,
} from '../graphql/user/getMyProfile'
import FullScreenLoader from './Shared/FullScreenLoader'
import Navigation from './Navigation/Navigation'
import { ROUTES } from '../const/routes'
import NoMatch from './NoMatch'
import UsersList from '../pages/ParkingPool/UsersList'
import Reservations from '../pages/ParkingPool/Reservations'
import ParkingPoolData from '../pages/ParkingPool/ParkingPoolData'
import Home from '../pages/Home'
import ParkingOccupancyReport from '../pages/ParkingPool/ParkingOccupancyReport'
import HIDUserList from '../pages/HID/HIDUserList'
import { PARKING_POOL_ROLES, HID_ROLES } from '../const/permissions'
import AuthWrapper from '../components/AuthWrapper'
import HIDServiceData from '../pages/HID/HIDServiceData'

const SignedInContainer = () => {
  const dispatch = useAppDispatch()

  const setUserData = ({
    firstName,
    lastName,
    login,
    tenant,
    language,
    roles,
    email,
    workEmail,
  }: IMyProfile) =>
    dispatch({
      type: UserActionTypes.SET_USER_DATA,
      data: {
        firstName,
        lastName,
        login,
        tenant: !!tenant?.name,
        tenantName: tenant?.name,
        language,
        roles: roles.map(role => role.name),
        email: email?.email,
        workEmail: workEmail?.email,
      },
    })

  const onCompleted = ({ getMyProfile }: IGetMyProfileData) => {
    if (getMyProfile.status) {
      setUserData(getMyProfile.user)
    }
  }

  const { loading } = useQuery<IGetMyProfileData>(GET_MY_PROFILE_QUERY, {
    onCompleted,
    onError: (error: ApolloError) => console.log(error.message),
    fetchPolicy: 'no-cache',
  })

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Navigation>
      <Routes>
        <Route path={ROUTES.HOME} element={<Home />} />
        <Route
          path={ROUTES.PARKING_POOL_DATA}
          element={
            <AuthWrapper requiredRoles={PARKING_POOL_ROLES}>
              <ParkingPoolData />
            </AuthWrapper>
          }
        />

        <Route
          path={ROUTES.PARKING_POOL_USERS}
          element={
            <AuthWrapper requiredRoles={PARKING_POOL_ROLES}>
              <UsersList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_POOL_RESERVATIONS}
          element={
            <AuthWrapper requiredRoles={PARKING_POOL_ROLES}>
              <Reservations />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.PARKING_POOL_OCCUPANCY_REPORT}
          element={
            <AuthWrapper requiredRoles={PARKING_POOL_ROLES}>
              <ParkingOccupancyReport />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.HID_USERS}
          element={
            <AuthWrapper requiredRoles={HID_ROLES}>
              <HIDUserList />
            </AuthWrapper>
          }
        />
        <Route
          path={ROUTES.HID_DATA}
          element={
            <AuthWrapper requiredRoles={HID_ROLES}>
              <HIDServiceData />
            </AuthWrapper>
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Navigation>
  )
}

export default SignedInContainer
